import React from 'react'
import {graphql} from 'gatsby'
import {Icon, Tag} from 'antd'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PropTypes from 'prop-types'

const Changelogs = ({data}) => (
    <Layout>
         <SEO title="Changelogs" description="Find out about all the recent changes we've made."/>
        <main>
            <div className="main-wrapper">
                <h1 className="title">Changelogs</h1>
                <div className="changelogs">
                    {data.allChangelogsJson.edges.map(edge => 
                    <Changelog 
                    key={edge.node.key} 
                    month={edge.node.month} 
                    entries={edge.node.entries}/>)}
                </div>
            </div>
        </main>
    </Layout>
)

Changelogs.propTypes = {
    data: PropTypes.object
}

const Changelog = ({month, entries}) => (
    <div className="changelog">
        <div className="date">
            <p className="meta">Changelog <Icon type="rocket" theme="twoTone" /> {month}</p>
        </div>
        <ul>
            {entries.map(entry => <li><Entry entry={entry} key={entry.key}/></li>)}
        </ul>
    </div>
)

Changelog.propTypes = {
    month: PropTypes.string,
    entries: PropTypes.entries
}

const Entry = ({entry}) => (
    <div className="entry">
        <div className="type">
            <Tag color={entry.color}>{entry.type}</Tag>
        </div>
        <div className="description">
            {entry.description}
        </div>
    </div>
)

Entry.propTypes = {
    entry: PropTypes.object
}

export const query = graphql`
{
  allChangelogsJson {
    edges {
      node {
        key,
        month, 
        entries {
          key
          color
          type
          description
        },
      }
    }
  }
}
`

export default Changelogs;